import React from 'react'
import Layout from '../components/layouts'
import * as ds from '../uiLibrary'
import styled from 'styled-components'
import { InnerPageHero } from '../components/heroes'
import tonitMedia from '../images/tonit-article.jpg'
import { graphql } from 'gatsby'
import Content, { MdContent } from "../components/Content"

const StyledDate = styled(ds.Eyebrow)`
  color: ${ds.colors.typography.darkTertiary};
  font-size: ${ds.typeScale.t3};
`

export const PressPostTemplate = ({
  content,
  contentComponent,
  title,
  date,
  featuredImage,
}) => {
  const PostContent = contentComponent || Content

  return (
    <ds.Div>
      <StyledDate>{date}</StyledDate>
      <ds.Headline color={ds.colors.typography.darkPrimary}>{title}</ds.Headline>
      <ds.CenteredImg src={featuredImage} maxWidth="auto" maxHeight={["200px", "300px", "400px"]} />
      <PostContent content={content} />
    </ds.Div>
  )
}

export default function PressPost({ data }) {
  const { pressJson } = data

  return (
    <Layout>
      <InnerPageHero url={tonitMedia} alt="Tonit Motorcycle Media">
        <ds.FixedWidthRow>
          <ds.Column span={8}>
            <ds.Headline >
              {pressJson.title}
            </ds.Headline>
          </ds.Column>
        </ds.FixedWidthRow>
      </InnerPageHero>
      <ds.Background bg={ds.colors.white}>
        <ds.FixedWidthRow>
          <ds.Column span={12}>
            <PressPostTemplate
              content={pressJson.body}
              contentComponent={MdContent}
              title={pressJson.title}
              date={pressJson.date}
              featuredImage={pressJson.featuredImage}
            />
          </ds.Column>
        </ds.FixedWidthRow>
      </ds.Background>
    </Layout>
  )
}

export const pageQuery = graphql`
  query PressById($id: String!) {
    pressJson(id: {eq: $id}) {
      id
      body
      date(formatString: "MMMM DD, YYYY")
      featuredImage
      title
    }
  }
`
